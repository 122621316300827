@import "variables";

.beryl_home-page {
  display: flex;
  flex-direction: column;
  background-color: #f5f7f8;
  overflow: scroll;
  height: 100vh;
  width: 100%;

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 1.5rem 2rem;

    &--right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &--content {
    display: flex;
    gap: 5rem;
    justify-content: center;
    margin: 5rem 2rem;
    flex-wrap: wrap;
  }

  .welcome-banner {
    background: #abbaab; /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #b6ceb6, #cde2cd); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to top,
      #b6ceb6,
      #cde2cd
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 1rem;
    padding: 2rem 3rem;
    width: 430px;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    color: black;
    height: fit-content;
    gap: 1rem;

    h2 {
      font-size: 1.8rem;
      font-weight: 500;

      span {
        font-size: 1.6rem;
        border: solid 1.2px black;
        padding: 0.2rem 0.6rem;
        border-radius: $border-radius;
        margin-left: 0.2rem;
        font-weight: 400;
      }
    }
    p {
      max-width: 650px;
      color: #414141;
      font-size: 0.95rem;
    }
  }

  .beryl_projects {
    max-width: 800px;
    width: 800px;

    .beryl_projects--section {
      margin-bottom: 2rem; // Add spacing between sections
      margin-top: 1.2rem;
      position: relative;

      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem; // Space between each project card
        padding: 0;
        list-style-type: none; // Remove list style
        margin: 0 -0.6rem;
      }

      li {
        width: calc(33.333% - 1rem); // Each project card takes up 1/3 of the container's width, minus the gap
        display: flex;
        justify-content: center; // Center the project card within the list item
        align-items: center; // Center the project card vertically within the list item
        width: fit-content;
        height: fit-content;

        @media (max-width: 768px) {
          width: calc(50% - 1rem); // On smaller screens, adjust to 2 columns
        }

        @media (max-width: 480px) {
          width: 100%; // On very small screens, each project card takes up the full width
        }
      }

      .beryl_project-card {
        width: 9.2rem;
        height: 100%;
        padding: 0.5rem 0rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        overflow: hidden;

        .beryl_project-card--title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 8rem;
          text-align: center;
          font-size: 0.95rem;
        }

        &--menubutton {
          position: absolute;
          top: 1rem;
          right: 0.6rem;
          transition: ease-in 0.15s;
          color: white;

          &:hover {
            opacity: 0.5;
          }
        }

        &--square {
          height: 8rem;
          width: 8rem;
          border-radius: 1rem;
          background-color: rgb(35, 35, 42);
          font-size: 3rem;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 1px 2px 6px 2px #cfd1d1;
          margin-bottom: 0.5rem;
          transition: ease-in 0.15s;
          color: rgba(255, 255, 255, 0.78);

          &:hover {
            scale: 1.02;
            box-shadow: 1px 2px 6px 2px #b7b8b8;
            color: white;
          }
        }
      }
    }
  }
}

.beryl_create-project-btn {
  width: 9.2rem;
  height: 100%;
  padding: 0.5rem 0rem;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: $text-light;
  transition: ease-in 0.15s;

  .square {
    height: 8rem;
    width: 8rem;
    border-radius: 1rem;
    border: dashed 1px $text-light;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &:hover,
  &:hover .square {
    color: black;
    border-color: black;
  }
}

.h1-title {
  font-size: 2rem;
  font-weight: 500;
}
