@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .app,
  #root,
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}

@layer components {
  @import "nicejsonform";
  @import "map/menu";

  .tooltip-button {
    @apply text-gray-700 hover:border-emerald-800 p-1 rounded hover:bg-emerald-800/10 flex justify-center gap-1 items-center w-8 h-8;
    &--text {
      @extend .tooltip-button;
      @apply p-2;
      width: fit-content;
      height: fit-content;
    }
  }
}

@import "text-editor";
@import "colorswatch";
@import "sidebar";
@import "layout";
@import "form";
@import "effects";
@import "home";
@import "jsoneditor/import";
@import "jsoneditor/processing";
@import "jsoneditor/inversion";
