@import "variables";

.beryl_divider {
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
}

.beryl_sidebar {
  background-color: white;
  border-radius: $border-radius;
  border: $border-style;

  position: relative;
  display: flex;
  flex-direction: column;
  transition: margin-top 0.3s width 0.3s ease;
  max-height: 100%;
  overflow: hidden;

  &.collapsed {
    height: fit-content;
  }

  .beryl_sidebar-title {
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    min-width: 0;
    margin-right: 1rem;
  }

  .beryl_sidebar-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0.8rem;

    &--controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .beryl_sidebar-header--left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-grow: 1;
        overflow: hidden;
      }
    }
  }

  .beryl_sidebar-footer {
    padding: 0.6rem 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-top: $divide-border-style;
  }

  .beryl_sidebar-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: opacity 0.3s ease-in-out;
    overflow: hidden;
  }

  &.collapsed .beryl_sidebar-content {
    display: none;
  }
}

.beryl_sidebar-tabs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .tabs {
    padding: 0.6rem 0.8rem;
    display: flex;
    border-top: $divide-border-style;
    border-bottom: $divide-border-style;
    .tab-button {
      padding: 0.25rem 0.5rem;
      border-radius: $border-radius;
      font-size: 0.975rem;
      color: $text-light;
    }
    .tab-button.active {
      color: black;
      background-color: $bg-active-state;
    }
  }
  .tab-content {
    display: flex;
    padding: 0.8rem;
    flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0; // Added to ensure it shrinks within its parent
    min-width: 0;
    overflow-y: scroll;
  }
}

.beryl_tree-navigation {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  flex-grow: 1;
  flex-grow: 1;
  flex-basis: 0; // Added to ensure it shrinks within its parent
  min-width: 0;

  .beryl_tree-navigation-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    h5 {
      font-weight: 500;
      color: $text-light;
      font-size: 0.875rem;
    }
  }
}

.beryl_version_select {
  background-color: #c2c2c2;
  border: solid 1px #aeaeae;
  color: #000000;
  border-radius: $border-radius;
  padding: 0.3rem 0.5rem;
  font-size: 0.75;
}

.beryl_dropdown-menu {
  position: relative;
  display: inline-block;

  .beryl_dropdown-toggle {
    display: flex;
    align-items: center;
    transition: 0.2s ease-in;
    &:hover {
      scale: 1.04;
    }
    &--icon {
      background-color: #000000;
      border-radius: $border-radius;
      padding: 0.4rem;
    }
  }

  .beryl_dropdown-content {
    position: fixed;
    background-color: white;
    min-width: 300px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border: $border-style;
    z-index: 100;
    border-radius: $border-radius;
    margin-top: 0.6rem;
    font-size: 0.975rem;

    .beryl_support-title {
      font-size: 0.875rem;
      color: $text-light;
      padding: 0 1.3rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    &--link {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
      padding: 0.8rem 1.3rem;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        color: $text-light;

        svg {
          color: $text-light;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      list-style: none;
      margin: 0 0.5rem;
      padding: 0;
      margin-bottom: 0.5rem;

      li {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 0.8rem 1rem;
        cursor: pointer;
        border-radius: $border-radius;
        transition: background-color 0.3s;

        &.active {
          background-color: $bg-active-state;
        }

        &.disabled {
          color: rgb(157, 157, 157);
          cursor: not-allowed;
          pointer-events: none;
        }

        &:hover:not(.disabled) {
          background-color: #f1f1f1;
        }
      }
    }
  }
}

.resize-handle {
  width: 8px;
  cursor: ew-resize;
  background-color: transparent;
  position: absolute;
  height: 100%; /* Adjust height to match the sidebar */
  top: 0;
  right: 0;
  z-index: 10;
  transition: ease-in-out;

  &:hover {
    background-color: #000000;
  }

  &.hidden {
    display: none;
  }
}

.beryl_workspace-layout {
  background-color: $bg-color;
  padding: 0.8rem;
  display: flex;
  gap: 0.8rem;
  height: 100vh;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;

  &--main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.beryl_plot-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .beryl_plot-toolbar-container {
    background-color: white;
    border-radius: $border-radius;
    padding: 0.4rem 0.8rem;
    border: $border-style;
    flex: 0 0 auto; // Prevents the toolbar from shrinking
    width: 100%; // Default width, considering sidebar width
    transition: width 0.3s ease;
    z-index: 1;

    .beryl_plot-toolbar {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      justify-content: left;
      flex-wrap: wrap;

      &--group {
        display: flex;
        align-items: center;
        width: fit-content;
      }

      &--right {
        margin-left: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }

  .beryl_plot-main-content {
    flex-grow: 1; // Take up the remaining space in the vertical direction
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease; // Smooth transition when sidebar toggles

    .beryl_plot {
      flex-grow: 1; // This allows the plot to fill the remaining vertical space
      display: flex;
      align-items: stretch;
      justify-content: left;
      overflow: hidden; // Prevent overflow issues
      width: 100%;
      height: 100%;

      .beryl_plot-inner {
        position: relative;
        height: 100%;
        width: 98%;
      }
    }

    .beryl_metadata-bar {
      background-color: rgb(227, 228, 230);
      border-radius: $border-radius;
      border: $border-style;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      padding: 0.4rem 0.8rem;
      &--group {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 0.75rem;
        gap: 0.5rem;
      }
    }
  }

  &.sidebar-collapsed .beryl_plot-main-content {
    margin-left: -310px; // Adjust to move left into the space vacated by the sidebar
  }
}

.beryl_support-widget-button {
  text-align: left;
  padding: 0.5rem 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.empty-project-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .empty-project-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-top: -5rem;
  }

  h2 {
    font-size: 1.5rem;
    color: #333; // Adjust text color
  }

  .create-group-btn {
    width: fit-content;
  }
}

.create-group-btn {
  padding: 0.4rem 0.8rem;
  border-radius: $border-radius;
  border: dashed 1px $text-light;
  color: $text-light;
  text-align: center;
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 0.95rem;
  transition: 0.15s ease;

  &:hover {
    border-color: black;
    color: black;
  }
}

.beryl_misc-data {
  width: 100%;

  .group-container {
    margin-bottom: 0.5rem;
  }

  .group-title {
    padding: 0.3rem 0.5rem;
    background-color: transparent;
    border-radius: $border-radius;
    font-weight: 500;
    margin-left: -0.3rem;
    margin-right: -0.3rem;
    font-size: 0.95rem;

    &:hover {
      background-color: $bg-active-state;
    }
  }

  .empty-list {
    padding: 0.5rem;
    color: $text-light;
  }

  .beryl_misc-data-list {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0.5rem 0.3rem;
  }

  .misc-data-list-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;

    .misc-data-list-item-tags {
      display: flex;
      display: flex;
      align-items: center;
      justify-content: right;
      gap: 0.2rem;
      font-size: 0.875rem;
      font-weight: 400;
      color: $text-light;
      width: fit-content;

      span {
        display: flex;
        font-size: 0.75;
      }
    }
    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20rem;
      font-size: 0.75rem;
      font-weight: 400;
      width: 33px;

      &--full {
        @extend .tag;
        width: fit-content;
        padding: 0 0.3rem;
        font-size: 0.785rem;
      }
    }

    &--title {
      width: 100%;
      flex-grow: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 0.875rem;
    }

    &--right {
      display: flex;
      align-items: center;
      margin-right: auto;
      position: relative;
    }

    &__menu {
      margin-top: 3rem;
      margin-left: 2.5rem;
      z-index: 800;
      position: fixed;
      background-color: white;
      border: $border-style;
      padding: 0.4rem 0.4rem;
      border-radius: $border-radius;
      width: 200px;
      box-shadow: 1px 2px 6px 2px #7d7d7d38;
    }
  }
}

.beryl_misc-data-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.misc-data-list-item-info {
  z-index: 1000;
  position: fixed;
  background-color: white;
  border: $border-style;
  padding: 0.4rem 0.4rem;
  border-radius: $border-radius;
  width: 200px;
  box-shadow: 1px 2px 6px 2px #7d7d7d38;
}

.misc-data-menu-btn {
  &:hover {
    opacity: 0.8;
  }
}

.misc-data-search {
  flex-grow: 1;
  padding: 0.3rem 0.8rem;
  border: $divide-border-style;
  border-radius: $border-radius;
  min-width: 0;
  margin-right: 0.4rem;
}

// ------------------------------ OLD
.beryl_sidenav {
  overflow: visible;
  z-index: 900;
}

.sidebar {
  transition: width 0.4s ease-in-out;
  position: sticky;
  top: 0;
  z-index: 800;
  width: 100%;
  overflow: visible;

  &__main {
    height: fit-content;
    overflow: scroll;
  }
  &__element-info {
    margin-top: auto;
    flex-grow: 1;
    height: fit-content;
    padding: 1rem;
  }
  .element-title {
    white-space: nowrap;
  }
  .group-options-menu {
    margin-top: 1.8rem;
    margin-right: -8rem;
  }
}

// override splitpane color
.split-sash-content.split-sash-content-active.split-sash-content-vscode {
  background-color: rgb(69, 69, 72) !important;
}

.react-split__pane {
  overflow: visible !important;
}
