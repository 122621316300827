.beryl_color-swatch {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &--item {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
  }

  &--pointer {
    border-radius: 100%;
    height: 0.5rem;
    width: 0.5rem;
    background-color: white;
  }
}
